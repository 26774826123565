import {
	Box,
	Button,
	Container,
	Grid,
	InputLabel,
	Slider,
	Typography,
} from "@mui/material";
import { useReducer } from "react";
import {
	BlobbyRecursiveDivisionOptions,
	useBlobbyRecursiveDivision,
} from "./hooks/useBlobbyRecursiveDivision";
import { printMaze } from "./utils/MazePrintingUtils";

type BlobbyRecursiveDivisionInputModel = BlobbyRecursiveDivisionOptions;

function inputModelReducer(
	state: BlobbyRecursiveDivisionInputModel,
	update: Partial<BlobbyRecursiveDivisionInputModel>
) {
	return Object.assign({}, state, update);
}

export type BlobbyRecursiveDivisionProps = {};
export function BlobbyRecursiveDivision(props: BlobbyRecursiveDivisionProps) {
	const { runBlobbyRecursiveDivision } = useBlobbyRecursiveDivision();
	const [options, setOptions] = useReducer(inputModelReducer, {
		debug: false,
		canvasId: "blobby-recursive-division-maze",
		minimumRoomSize: 5,
		numOfWallBreaks: 3,
		size: 20,
	});

	return (
		<Box>
			<Grid container direction={"column"} spacing={2}>
				<Grid item>
					<Typography textAlign={"center"} variant={"h3"} gutterBottom>
						Blobby Recursive Division (Backrooms-style)
					</Typography>
				</Grid>
				<Grid item>
					<Grid container spacing={3} direction={"column"}>
						<Grid item>
							<InputLabel htmlFor={"min-room-size-input"}>
								Minimum Room Size
							</InputLabel>
							<Slider
								min={0}
								max={100}
								id={"min-room-size-input"}
								aria-label="Minimum Room Size"
								valueLabelDisplay={"auto"}
								value={options.minimumRoomSize}
								onChange={(e, newValue) =>
									setOptions({
										minimumRoomSize: Number(newValue) || 0,
									})
								}
							/>
						</Grid>
						<Grid item>
							<InputLabel htmlFor={"num-wall-breaks-input"}>
								Number of Breaks per Wall
							</InputLabel>
							<Slider
								min={0}
								max={100}
								id={"num-wall-breaks-input"}
								aria-label="Number of Wall Breaks"
								valueLabelDisplay={"auto"}
								value={options.numOfWallBreaks}
								onChange={(e, newValue) =>
									setOptions({
										numOfWallBreaks: Number(newValue) || 0,
									})
								}
							/>
						</Grid>
						<Grid item>
							<InputLabel htmlFor={"grid-size-input"}>Size of Grid</InputLabel>
							<Slider
								min={4}
								max={500}
								id={"grid-size-input"}
								aria-label="Grid Size"
								valueLabelDisplay={"auto"}
								value={options.size}
								onChange={(e, newValue) =>
									setOptions({
										size: Number(newValue) || 0,
									})
								}
							/>
						</Grid>
						<Grid item>
							<Button
								type="button"
								size={"medium"}
								fullWidth
								variant={"contained"}
								onClick={() =>
									printMaze(
										{
											grid: runBlobbyRecursiveDivision(options),
											height: options.size,
											width: options.size,
										},
										{ canvasId: "blobby-recursive-division-maze" }
									)
								}
							>
								Generate
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					<Container sx={{ textAlign: "center" }}>
						<canvas
							id="blobby-recursive-division-maze"
							width={"1000px"}
							height={"1000px"}
						/>
					</Container>
				</Grid>
			</Grid>
		</Box>
	);
}
