import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export function ProjectsLanding() {
	return (
		<Box>
			<Typography variant="h1">Projects</Typography>
			<Typography variant="h5">
				Welcome! Here are some of the projects I've worked on.
			</Typography>
			<Typography variant="body1">
				<Link to="recursive-division">My current favorite lives here</Link>
			</Typography>
		</Box>
	);
}
