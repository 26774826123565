import { createTheme, responsiveFontSizes, ThemeOptions } from "@mui/material";

// https://coolors.co/f6f7f3-e49ab0-000500-1e3888-957d95
export const themeOptions: ThemeOptions = {
	palette: {
		primary: {
			main: "#F7A9E1",
		},
		secondary: {
			main: "#92D5E5",
		},
		background: {
			default: "#F6F6F5",
			paper: "#F6F6F5",
		},
		text: {
			secondary: "#92D5E5",
		},
	},
	typography: {
		fontFamily: "'Nunito', 'Anek Latin', 'Roboto', sans-serif",
	},
};
export const theme = responsiveFontSizes(createTheme(themeOptions));
