import {
	randomFromArray,
	randomIndex,
	randomNumber
} from "../../../hooks/useGeneralUtils";
import { blankCanvas, blankCells, printMaze } from "../utils/MazePrintingUtils";


export type DirectionNames = "north" | "south" | "west" | "east";
export type MazeCellWalls = { [direction in DirectionNames]: boolean };
export type Cell = {
	name: string;
	walls: MazeCellWalls;
	x: number;
	y: number;
	region?: "a" | "b";
	debugColor?: string;
};
export type Maze = {
	grid: CellGrid;
	width: number;
	height: number;
};
export type CellGrid = {
	[name: string]: Cell;
};
export type BlobbyRecursiveDivisionOptions = {
	size: number;
	canvasId: string;
	numOfWallBreaks?: number;
	minimumRoomSize?: number;
	debug?: boolean;
};


export function useBlobbyRecursiveDivision() {
	return { runBlobbyRecursiveDivision };
}

function runBlobbyRecursiveDivision(options: BlobbyRecursiveDivisionOptions) {
	// reset maze
	blankCanvas({canvasId: options.canvasId});

	console.time("Blobby Recursive Division Generation");

	// group all cells into one region
	const initialRegion: CellGrid = {};
	const maze: CellGrid = {};
	// populate said region
	for (let x = 0; x < options.size; x++) {
		for (let y = 0; y < options.size; y++) {
			const name = cellName(x, y);
			const cell: Cell = {
				x,
				y,
				name,
				walls: { east: false, west: false, north: false, south: false },
			};
			if (!initialRegion[name]) initialRegion[name] = cell;
			if (!maze[name]) maze[name] = cell;
		}
	}

	// begin the splitting recursive tree
	split(initialRegion, options, maze);

	for (const cell in maze) {
		maze[cell].debugColor = undefined;
	}

	console.timeEnd("Blobby Recursive Division Generation");
	return maze;
}

function split(
	region: CellGrid,
	options: BlobbyRecursiveDivisionOptions,
	maze: CellGrid
) {
	let cellsLeft = Object.values(region);
	let subregionA: CellGrid = {};
	let subregionB: CellGrid = {};
	let regionAQueue: Cell[] = [];
	let regionBQueue: Cell[] = [];

	// seed two random cells to use in splitting
	let seedA = cellsLeft.splice(randomIndex(cellsLeft), 1)[0];
	if (!seedA) return;
	seedA.region = "a";
	subregionA[seedA.name] = seedA;
	region[seedA.name] = seedA;
	regionAQueue.push(seedA);

	let seedB = cellsLeft.splice(randomIndex(cellsLeft), 1)[0];
	if (!seedB) return;
	seedB.region = "b";
	subregionB[seedB.name] = seedB;
	region[seedB.name] = seedB;
	regionBQueue.push(seedB);

	const roomSize = options?.minimumRoomSize || 0;
	// assign every cell to a half
	while (regionAQueue.length > 0 || regionBQueue.length > 0) {
		const useRegionA = randomNumber(2) === 1;

		// get a random cell from the queue
		const queuedCells = useRegionA ? regionAQueue : regionBQueue;
		let randomCell = randomFromArray(queuedCells);
		if (!randomCell) {
			if (regionAQueue.length > 0) randomCell = randomFromArray(regionAQueue);
			if (regionBQueue.length > 0) randomCell = randomFromArray(regionBQueue);
			if (!randomCell) {
				console.error("Failed to get random cell!");
				break;
			}
		}

		const randomCellIndx = cellsLeft.findIndex(
			(e) => e.name === randomCell.name
		);
		if (randomCellIndx > -1) cellsLeft.splice(randomCellIndx, 1);

		// for each neighbor of this cell
		const neighbors = [
			region[getNeighborName(randomCell, "north")],
			region[getNeighborName(randomCell, "south")],
			region[getNeighborName(randomCell, "east")],
			region[getNeighborName(randomCell, "west")],
		];
		for (const neighbor of neighbors) {
			// ignore out of bounds neighbors
			if (!neighbor) continue;
			// if the neighbor isn't assigned a region
			if (neighbor.region === undefined) {
				// remove cell from the cells left array
				cellsLeft.splice(
					cellsLeft.findIndex((e) => e.name === neighbor.name),
					1
				);
				// set cell's region to match this cell's region
				neighbor.region = randomCell.region;
				(neighbor.region === "a" ? subregionA : subregionB)[neighbor.name] =
					neighbor;
				// add cell to queue to be processed
				(neighbor.region === "a" ? regionAQueue : regionBQueue).push(neighbor);
			}
		}

		// remove processed cell from queue
		const regionAIndex = regionAQueue.findIndex(
			(e) => e.name === randomCell.name
		);
		const regionBIndex = regionBQueue.findIndex(
			(e) => e.name === randomCell.name
		);
		if (regionAIndex > -1) {
			regionAQueue.splice(regionAIndex, 1);
		} else if (regionBIndex > -1) {
			regionBQueue.splice(regionBIndex, 1);
		}
		if (options?.debug) {
			for (let cell in { ...subregionA, ...subregionB }) {
				let cellValue = subregionA[cell] || subregionB[cell];
				maze[cell].debugColor = undefined;
				if (cellValue.region === "b")
					maze[cell].debugColor = "rgb(255,207,207)";
				if (cellValue.region === "a")
					maze[cell].debugColor = "rgb(208,255,207)";
			}
			printMaze(
				{
					grid: maze,
					width: options.size,
					height: options.size,
				},
				{ canvasId: options.canvasId }
			);
			console.info("Debug Print");
		}
	}

	// when all cells have been split, construct walls on the border of the two regions
	const walledCells: {
		cell: string;
		neighborCell: string;
		direction: DirectionNames;
	}[] = [];
	// go over every cell
	for (let cell in subregionA) {
		let cellValue = subregionA[cell];
		// check each neighbor for presence in the other region
		const directions: DirectionNames[] = ["north", "south", "east", "west"];
		for (let direction of directions) {
			const neighborCell = getNeighborName(cellValue, direction);
			if (subregionB[neighborCell]) {
				maze[cellValue.name].walls[direction] = true;
				walledCells.push({ cell: cellValue.name, neighborCell, direction });
			}
		}
	}

	for (let i = 0; i < (options?.numOfWallBreaks || 1); i++) {
		const randomWalledCell = randomFromArray(walledCells);
		if (randomWalledCell) {
			maze[randomWalledCell.cell].walls[randomWalledCell.direction] = false;
			walledCells.splice(
				walledCells.findIndex(
					(e) =>
						e.direction === randomWalledCell.direction &&
						e.cell === randomWalledCell.cell
				),
				1
			);
		}
	}

	// debug print
	if (options?.debug) {
		blankCells(
			{
				grid: maze,
				width: options.size,
				height: options.size,
			},
			{ canvasId: options.canvasId }
		);
	}
	for (let cell in { ...subregionA, ...subregionB }) {
		let cellValue = subregionA[cell] || subregionB[cell];
		cellValue.debugColor = undefined;
		cellValue.region = undefined;
	}
	// split each region further, unless room size is acheived
	if (getCellGridSize(subregionA) > roomSize) split(subregionA, options, maze);
	if (getCellGridSize(subregionB) > roomSize) split(subregionB, options, maze);
}

function cellName(x: number, y: number) {
	return `row${y}col${x}`;
}

function getNeighborName(cell: Cell, direction: DirectionNames) {
	switch (direction) {
		case "north":
			return `row${cell.y - 1}col${cell.x}`;
		case "south":
			return `row${cell.y + 1}col${cell.x}`;

		case "west":
			return `row${cell.y}col${cell.x - 1}`;

		case "east":
			return `row${cell.y}col${cell.x + 1}`;
	}
}

function getCellGridSize(grid: CellGrid) {
	return Object.values(grid).length;
}
