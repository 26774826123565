import { createCtx } from "../hooks/createCtx";

export type AppStateActions = {
	updateAppState: (state: AppState) => void;
};
export type AppState = {};
export type AppContextType = {
	appState: AppState;
	actions: AppStateActions;
};
export const [useAppState, AppProvider] = createCtx<AppContextType>();
