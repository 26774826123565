import { KeyboardDoubleArrowDown, Workspaces } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
	Collapse,
	Container,
	Fade,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	styled,
	Toolbar,
	Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const LandingBox = styled(Box)`
	background-image: url("/images/abstract-backsplash.jpg");
	-webkit-mask-image: -webkit-linear-gradient(
		0deg,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 1) 25%,
		rgba(0, 0, 0, 1) 75%,
		rgba(0, 0, 0, 0) 100%
	);
	mask-image: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 1) 25%,
		rgba(0, 0, 0, 1) 75%,
		rgba(0, 0, 0, 0) 100%
	);
	height: 100vh;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
`;
export type BackgroundImageBoxProps = {
	imageUrl: string;
	fadeAtBottom?: boolean;
};
export function BackgroundImageBox({}: BackgroundImageBoxProps) {
	return <LandingBox />;
}

const CenterHeroContainer = styled(Container)`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 50vh;
`;
const StyledAppBar = styled(Toolbar)`
	background: none;
	box-shadow: none;
`;

export function LandingNavigation() {
	const drawerWidth = 240;
	const [drawerOpen, setDrawerOpen] = useState(false);
	const navigate = useNavigate();

	const handleDrawerToggle = () => {
		setDrawerOpen(!drawerOpen);
	};

	const drawer = (
		<Box>
			<Toolbar>
				<Box sx={{ width: "100%", height: "100%" }}>
					<IconButton
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						sx={{ mr: 4, zIndex: 10, float: "right" }}
					>
						<MenuIcon />
					</IconButton>
				</Box>
			</Toolbar>
			<List>
				<ListItem button onClick={() => navigate("projects")}>
					<ListItemIcon>
						<Workspaces />
					</ListItemIcon>
					<ListItemText primary={"Projects"} />
				</ListItem>
			</List>
		</Box>
	);

	return (
		<>
			<StyledAppBar sx={{ zIndex: 10 }}>
				<Typography variant={"h4"} sx={{ flexGrow: 1 }}>
					Alex Slater
				</Typography>
				<IconButton
					aria-label="open drawer"
					edge="start"
					onClick={handleDrawerToggle}
					sx={{ mr: 2, zIndex: 10 }}
				>
					<MenuIcon />
				</IconButton>
			</StyledAppBar>
		</>
	);
}

export type LandingProps = {};
export function Landing({}: LandingProps) {
	return (
		<>
			<LandingNavigation />
			<LandingHero />
			<WhoAmI />
		</>
	);
}

export function LandingHero() {
	const [triggerAnimation, setTriggerAnimation] = useState(false);

	useLayoutEffect(() => {
		setTriggerAnimation(true);
	}, []);
	return (
		<LandingBox sx={{ p: 1 }}>
			<CenterHeroContainer maxWidth={"md"}>
				<Grid container direction={"column"} spacing={3}>
					<Grid item>
						<Fade
							in={triggerAnimation}
							timeout={triggerAnimation ? 1000 : undefined}
						>
							<Box sx={{ pl: 3, pr: 3, pt: "35vh" }}>
								<Typography variant={"h1"}>
									<Box>
										<Box sx={{ fontWeight: "bold", display: "inline" }}>
											Alex{" "}
										</Box>
										<Box sx={{ color: "text.secondary", display: "inline" }}>
											Slater
										</Box>
									</Box>
									<Box>
										<Box sx={{ fontWeight: "bold", display: "inline" }}>
											Software{" "}
										</Box>
										<Box sx={{ color: "text.secondary", display: "inline" }}>
											Engineer
										</Box>
									</Box>
								</Typography>
							</Box>
						</Fade>
					</Grid>
					<Grid item textAlign={"center"} sx={{ mt: 5 }}>
						<Collapse
							in={triggerAnimation}
							timeout={triggerAnimation ? 1000 : undefined}
						>
							<KeyboardDoubleArrowDown sx={{ fontSize: "5rem" }} />
						</Collapse>
					</Grid>
				</Grid>
			</CenterHeroContainer>
		</LandingBox>
	);
}

export function WhoAmI() {
	return (
		<Container maxWidth={"md"} sx={{ textAlign: "center" }}>
			<Box>
				<Typography variant={"h2"}>
					Work in progress... Check in soon!
				</Typography>
			</Box>
		</Container>
	);
}
