export function useGeneralUtils() {
	return { randomFromArray, randomIndex, randomNumber };
}

export function randomFromArray<T>(array: Array<T>) {
	return array[randomIndex(array)];
}

export function randomIndex(array: any[]) {
	return randomNumber(array.length);
}

export function randomNumber(max: number, min?: number) {
	return (min || 0) + Math.floor(Math.random() * max);
}
