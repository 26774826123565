import { Maze } from "../hooks/useBlobbyRecursiveDivision";

export type PrintMazeOptions = {
	canvasId: string;
};
export function printMaze(
	{ grid: maze, height, width }: Maze,
	options: PrintMazeOptions
) {
	let canvas = document.getElementById(options.canvasId) as HTMLCanvasElement;
	if (!canvas) return console.error("failed to get canvas");
	let ctx = canvas.getContext("2d");
	if (!ctx) return console.error("2d context failed");
	blankCanvas(options);
	ctx.restore();
	ctx.save();
	ctx.scale(16.55 / height, 16.55 / width);

	for (const cell in maze) {
		const cellVal = maze[cell];
		const cellX = 25 + cellVal.x * 55;
		const cellY = 25 + cellVal.y * 55;
		const wallX = cellX;
		const wallY = cellY;

		ctx.fillStyle = cellVal.debugColor || "rgb(255, 255, 255)";
		ctx.fillRect(cellX, cellY, 50, 50);
		ctx.fillStyle = "rgb(0, 0, 200)";
		if (cellVal.walls.north) {
			ctx.fillRect(wallX - 5, wallY - 5, 60, 5);
		}
		if (cellVal.walls.south) {
			ctx.fillRect(wallX - 5, wallY + 50, 60, 5);
		}
		if (cellVal.walls.east) {
			ctx.fillRect(wallX + 50, wallY, 5, 55);
		}
		if (cellVal.walls.west) {
			ctx.fillRect(wallX - 5, wallY - 5, 5, 55);
		}
	}
}

export function blankCells(
	{ grid: maze, height, width }: Maze,
	options: PrintMazeOptions
) {
	let canvas = document.getElementById(options.canvasId) as HTMLCanvasElement;
	if (!canvas) return console.error("failed to get canvas");
	let ctx = canvas.getContext("2d");
	if (!ctx) return console.error("2d context failed");

	ctx.restore();
	ctx.save();
	ctx.scale(16.6 / height, 16.6 / width);

	for (let cell in maze) {
		const { x, y } = maze[cell];
		const cellX = 25 + x * 55;
		const cellY = 25 + y * 55;
		ctx.fillStyle = "rgb(255, 255, 255)";
		ctx.fillRect(cellX, cellY, 50, 50);
	}
}

export function blankCanvas(options: PrintMazeOptions) {
	let canvas = document.getElementById(options.canvasId) as HTMLCanvasElement;
	if (!canvas) return console.error("failed to get canvas");
	let ctx = canvas.getContext("2d");
	if (!ctx) return console.error("2d context failed");
	ctx.restore();
	ctx.fillStyle = "rgb(240, 240, 240)";
	ctx.fillRect(0, 0, canvas.width, canvas.height);
}